import {currentUserActions, currentUserSelectors} from "@features/currentUser";
import {ReactComponent as LogoNoe} from "@images/logo-base.svg";
import {Pending} from "@shared/components/Pending";
import PwaInstallPromptHandler from "@shared/components/PwaInstallPromptHandler";
import {useRegisterServiceWorker} from "@shared/hooks/useRegisterServiceWorker";
import {useStagingInfoNotification} from "@shared/hooks/useStagingInfoNotification";
import {useDarkModeTheme} from "@shared/layout/DynamicProjectThemeProvider";
import {Redirect} from "@shared/pages/Redirect";
import {useBrowserUpdate} from "@shared/services/browserUpdate";
import {initI18n, useUserLocale} from "@shared/services/i18n";
import {withSentryProfiler} from "@shared/services/sentry";
import {lazyWithRetry} from "@shared/utils/lazyWithRetry";
import isHotkey from "is-hotkey";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";

// Do not prefetch Forgot password pages
const ForgotPassword = lazyWithRetry(() => import("@shared/pages/auth/ForgotPassword.js"));
const ResetPassword = lazyWithRetry(() => import("@shared/pages/auth/ResetPassword.js"));

const LogIn = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "@shared/pages/auth/LogIn.js")
);
const SignUp = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "@shared/pages/auth/SignUp.js")
);

const MainLayout = lazyWithRetry(() => import(/* webpackPreload: true */ "@routes/MainLayout.js"));
const ProjectLayout = lazyWithRetry(
  () =>
    import(
      /* webpackPreload: true */
      "@routes/ProjectLayout"
    )
);
const ProjectList = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "@routes/projects/ProjectList")
);
const ProjectNew = lazyWithRetry(() => import("@routes/projects/ProjectNew"));
const ChangelogWidget = lazyWithRetry(() => import("@shared/components/ChangelogWidget"));

const isCtrlSavePressed = isHotkey("mod+S");
initI18n();

function App() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const connected = useSelector(currentUserSelectors.selectConnected);
  const user = useSelector(currentUserSelectors.selectUser);

  useUserLocale();
  useDarkModeTheme();
  useBrowserUpdate();

  // Remove Ctrl+S default behavior
  useEffect(() => {
    const onCtrlSKeyDown = (event: KeyboardEvent) =>
      isCtrlSavePressed(event) && event.preventDefault();
    window.addEventListener("keydown", onCtrlSKeyDown);
    return () => {
      window.removeEventListener("keydown", onCtrlSKeyDown);
    };
  }, []);

  const userCanCreateProject =
    user.superAdmin || process.env.REACT_APP_BLOCK_PROJECT_CREATION !== "true";

  useEffect(() => {
    if (connected === undefined) {
      dispatch(currentUserActions.refreshAuthTokens());
    }
  }, [connected, dispatch]);

  useStagingInfoNotification();

  useRegisterServiceWorker();

  return (
    <>
      {/* Show the PWA prompt 15 seconds after the user has some registration loaded */}
      <PwaInstallPromptHandler shouldShow={(registration) => registration?._id} delay={15000} />

      <Routes>
        {connected === false && (
          <>
            {/*Simple root auth URLs first*/}
            <Route
              path="/login"
              element={<LogIn subtitle={t("common:connectionPage.orgaLogIn")} />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />

            {/*Auth URLs with project ID just after*/}
            <Route
              path="/:projectId/login"
              element={<LogIn subtitle={t("common:connectionPage.orgaLogIn")} />}
            />
            <Route path="/:projectId/signup" element={<SignUp />} />
            <Route path="/:projectId/forgotpassword" element={<ForgotPassword />} />

            {/*Then, redirect /projects to /login if not connected, so /projects is not considered as a project slug*/}
            <Route path="/projects" element={<Redirect to="/login" />} />

            {/*Then, redirect any fucked-up /:projectId/**** URL to project login page (/:projectId/login) if not connected*/}
            <Route
              path="/:projectId/*"
              element={
                <Redirect from="/:projectId/*" to="/:projectId/login" paramsNames={["projectId"]} />
              }
            />

            {/*Redirect all the rest to /login*/}
            <Route path="/*" element={<Redirect from="/*" to="/login" />} />
          </>
        )}
        {connected && (
          <>
            {/*If the user is on the /login page, but is connected, redirect to /projects*/}
            <Route path="/login" element={<Redirect to="/projects" />} />

            {/*Main Layout*/}
            <Route path="/projects" element={<MainLayout page="projects" />}>
              <Route
                path=""
                element={<ProjectList userCanCreateProject={userCanCreateProject} />}
              />
            </Route>

            {/*Access to project creation page (escape the /new URL)*/}
            {userCanCreateProject && (
              <Route path="/new" element={<MainLayout />}>
                <Route path="" element={<ProjectNew id="new" />} />
              </Route>
            )}

            {/*Project Layout*/}
            <Route path="/:projectId/*" element={<ProjectLayout />} />

            {/*Redirect all the rest to /projects (which is accessible only when connected)*/}
            <Route path="/*" element={<Redirect to="/projects" />} />
          </>
        )}

        {/* PENDING SCREEN ON FIRST PAGE LOAD */}
        <Route
          path="/*"
          element={
            <Pending
              noFadeIn
              animationType={"breathe"}
              logo={
                <div style={{width: 130, height: 130}}>
                  <LogoNoe />
                </div>
              }
            />
          }
        />
      </Routes>

      {process.env.REACT_APP_HIDE_CHANGELOG_UPDATES !== "true" && <ChangelogWidget />}
    </>
  );
}

export default withSentryProfiler(App);
