// Handles dates when end date is at midnight o'clock. Without it, any session ending at midnight will
// overflow on the next day, which is really annoying. This manager aims to remove this behavior.
import dayjs, {dayjsBase, guessUserTimezone, PROJECT_TIMEZONE} from "@shared/services/dayjs";

export const getTimezoneOffsetDifferenceInHours = () => {
  const projectDateTime = dayjs();
  const browserDateTime = dayjsBase().tz(guessUserTimezone());

  return (browserDateTime.utcOffset() - projectDateTime.utcOffset()) / 60;
};

export const timezoneDiffManager = {
  set: (date) => dayjsBase(date).tz(PROJECT_TIMEZONE).tz(guessUserTimezone(), true).toISOString(),
  unset: (date) => dayjsBase(date).tz(guessUserTimezone()).tz(PROJECT_TIMEZONE, true).toISOString(),
};

const isMidnightHour = (hour: number) => [0, 24].includes(hour);

export const agendaSlotDatesManager = {
  // Takes an existing slot and returns this slot with startDate and endDate which are the dates
  // that will be used by the agenda to display it
  fromDataToDisplay: (slot) => {
    // Adjust dates for display
    let slotStartDate = dayjsBase(timezoneDiffManager.set(slot.startDate));
    let slotEndDate = dayjsBase(timezoneDiffManager.set(slot.endDate));

    // If end date is midnight, remove one minute
    if (isMidnightHour(slotEndDate.hour()) && slotEndDate.minute() === 0) {
      slotEndDate = slotEndDate.subtract(1, "minute");
    }

    return {
      ...slot,
      startDate: slotStartDate.toISOString(),
      endDate: slotEndDate.toISOString(),
    };
  },

  // Takes an agenda slot with already modified data, and returns the actual, real slot original date
  fromDisplayToData: (existingSlot, agendaSlot) => {
    // Adjust dates back to project timezone
    let agendaStartDate = dayjs(timezoneDiffManager.unset(agendaSlot.startDate));
    let agendaEndDate = dayjs(timezoneDiffManager.unset(agendaSlot.endDate));
    let existingEndDate = dayjs(timezoneDiffManager.unset(existingSlot.end));

    // console.log(
    //   "agendaStartDate",
    //   agendaStartDate.hour(),
    //   agendaStartDate.minute(),
    //
    //   "\nagendaEndDate",
    //   agendaEndDate.hour(),
    //   agendaEndDate.minute(),
    //
    //   "\nexistingEndDate",
    //   existingEndDate.hour(),
    //   existingEndDate.minute(),
    //
    //   "\ngetTimezoneOffsetDifferenceInHours",
    //   getTimezoneOffsetDifferenceInHours()
    // );

    // If the saved slot (before it was modified) date is midnight, then it means that
    // the agenda date was 23h59. So for any modification made in the agenda, the end date given y the agenda is one minute late.
    // So we need to add this minute back again
    if (
      isMidnightHour(existingEndDate.hour() - getTimezoneOffsetDifferenceInHours()) &&
      existingEndDate.minute() === 0
    ) {
      agendaEndDate = agendaEndDate.add(1, "minute");
    }

    return {
      start: agendaStartDate.set("second", 0).set("millisecond", 0).toISOString(),
      end: agendaEndDate.set("second", 0).set("millisecond", 0).toISOString(),
    };
  },
};
