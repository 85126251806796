import {initI18n} from "@shared/services/i18n";
import {persistor, store} from "@app/store";
import "@styles/App.less";
import {OnlineStatusProvider} from "@shared/hooks/useOnlineStatus";
import {useTheme} from "@shared/hooks/useTheme";
import {WindowDimensionsProvider} from "@shared/hooks/useWindowDimensions";
import {initSentry} from "@shared/services/sentry";
import {OFFLINE_MODE, useInitializeOfflineModeOnStart} from "@shared/utils/offlineModeUtilities";
import {UserTourProvider} from "@shared/utils/userTourUtilities";
import {App, ConfigProvider} from "antd";
import frFR from "antd/es/locale/fr_FR";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import NoeApp from "./App";

initSentry(0.05);
initI18n();

const Root: React.FC = () => {
  const {i18n} = useTranslation();
  const theme = useTheme();
  useInitializeOfflineModeOnStart();

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <WindowDimensionsProvider>
          <OnlineStatusProvider>
            <ConfigProvider locale={i18n.language === "fr" ? frFR : undefined} theme={theme}>
              <App>
                <UserTourProvider>
                  <NoeApp />
                </UserTourProvider>
              </App>
            </ConfigProvider>
          </OnlineStatusProvider>
        </WindowDimensionsProvider>
      </BrowserRouter>
    </Suspense>
  );
};

ReactDOM.render(
  <Provider store={store}>
    {OFFLINE_MODE ? (
      <PersistGate loading={null} persistor={persistor}>
        <Root />
      </PersistGate>
    ) : (
      <Root />
    )}
  </Provider>,
  document.getElementById("root")
);
